import React from 'react';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';

const StyledProductQuantity = styled.div``;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  color: ${standardColours.black};
  font-weight: ${fontWeights.semibold};
`;

const StyledQuantityWrap = styled.div`
  display: inline-flex;
`;

const StyledQuantityButton = styled.button`
  ${({ alt }) => (alt ? 'right: 0' : 'left: 0')};
  width: 45px;
  background: none;
  border: 1px solid ${brandColours.quaternary};

  &:before {
    content: ${({ alt }) => (alt ? '"+"' : '"-"')};
    ${fontSize(28)};
    line-height: ${({ alt }) => (alt ? '1.25' : '0.9')};
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledQuantity = styled.input`
  margin: 0 3px;
  height: 45px;
  width: 55px;
  text-align: center;
  background-color: ${standardColours.white};
  border: 1px solid ${brandColours.quaternary};
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const ProductQuantity = ({ quantity, maxQuantity, setQuantity, ...props }) => {
  const changeQuantity = value => {
    if (value === 'remove') {
      setQuantity(quantity > 1 ? quantity - 1 : 1);
    } else if (value === 'add') {
      setQuantity(
        quantity === maxQuantity ? quantity : quantity ? quantity + 1 : 1
      );
    } else {
      setQuantity(parseInt(value));
    }
  };

  return (
    <StyledProductQuantity {...props}>
      <StyledLabel>Quantity</StyledLabel>
      <StyledQuantityWrap>
        <StyledQuantityButton
          type="button"
          value="remove"
          onClick={e => changeQuantity(e.target.value)}
        >
          <span>Remove One</span>
        </StyledQuantityButton>
        <StyledQuantity
          type="number"
          id="product-form-quantity"
          name="quantity"
          value={quantity}
          min="1"
          max={maxQuantity}
          required
          onChange={e => changeQuantity(e.target.value)}
        />
        <StyledQuantityButton
          type="button"
          value="add"
          alt={true}
          onClick={e => changeQuantity(e.target.value)}
        >
          <span>Add One</span>
        </StyledQuantityButton>
      </StyledQuantityWrap>
    </StyledProductQuantity>
  );
};

export default ProductQuantity;
