import React, { useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  fontSize,
  visuallyHidden,
  inputStyles,
} from '../styles';
import { Button } from './ui';

const StyledOutOfStockForm = styled.form`
  margin-top: 14px;

  ${minBreakpointQuery.tiny`
    margin-top: 18px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 22px;
  `}
`;

const StyledText = styled.p`
  ${fontSize(14)};
  line-height: 1.5;

  ${minBreakpointQuery.tiny`
    ${fontSize(15)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledFields = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  background-color: ${standardColours.white};

  ${minBreakpointQuery.tiny`
    margin-top: 14px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
  `}
`;

const StyledLabel = styled.label`
  width: 100%;
`;

const StyledLabelText = styled.span`
  ${visuallyHidden()};
`;

const StyledInput = styled.input`
  ${inputStyles()}
`;

const StyledSubmit = styled(Button)`
  padding: 10px 12px;
  white-space: nowrap;

  ${minBreakpointQuery.large`
    padding-right: 15px;
    padding-left: 15px;
  `}
`;

const OutOfStockForm = ({ productId, variantId }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const outOfStockForm = document.getElementById('out-of-stock-form');
    const formData = new FormData(outOfStockForm);

    fetch('/api/outofstock', {
      method: 'POST',
      body: JSON.stringify(new URLSearchParams(formData).toString()),
    })
      .then(() => {
        setFormSubmitted(true);
        outOfStockForm.reset();
      })
      .catch(error => alert(error));
  };

  return (
    <StyledOutOfStockForm
      id="out-of-stock-form"
      method="POST"
      onSubmit={e => handleSubmit(e)}
    >
      <input type="hidden" name="product-number" value={productId} />
      <input type="hidden" name="quantity-required" value="1" />
      <input type="hidden" name="utc-offset" value="3600" />
      <input type="hidden" name="variant-number" value={variantId} />
      <StyledText>
        Sorry this product is currently Out of Stock, enter your email address
        below to be notified when it is available again.
      </StyledText>
      <StyledFields>
        <StyledLabel>
          <StyledLabelText>Email Address</StyledLabelText>
          <StyledInput
            type="email"
            name="email"
            placeholder="Email"
            required
            onFocus={() => setFormSubmitted(false)}
          />
        </StyledLabel>
        <StyledSubmit
          type="submit"
          title="Subscribe to newsletter"
          disabled={formSubmitted}
          alt={true}
        >
          {!formSubmitted ? 'Notify me' : 'Thanks'}
        </StyledSubmit>
      </StyledFields>
    </StyledOutOfStockForm>
  );
};

export default OutOfStockForm;
