import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
} from '../styles';
import ProductGallery from './ProductGallery';
import ProductSummaryForm from './ProductSummaryForm';

const StyledProductDetail = styled.div``;

const StyledInner = styled.div`
  ${minBreakpointQuery.small`
    display: grid;
    grid-template-columns: repeat(2, 50%);
  `}
`;

const StyledProductGalleryWrapper = styled.div`
  ${sectionPaddings()};
`;

const StyledProductGallery = styled(ProductGallery)`
  margin-left: auto;
  padding: 0 30px;
  max-width: 620px;

  ${minBreakpointQuery.smedium`
    padding-right: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    padding-right: 50px;
  `}

  ${minBreakpointQuery.xlarge`
    padding-right: 60px;
  `}
`;

const StyledProductSummaryFormWrapper = styled.div`
  ${sectionPaddings()};
  background-color: ${standardColours.lightGrey};

  ${minBreakpointQuery.small`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

const StyledProductSummaryForm = styled(ProductSummaryForm)`
  padding: 0 30px;
  max-width: 620px;

  ${minBreakpointQuery.smedium`
    padding-right: 40px;
    padding-left: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    padding-right: 50px;
    padding-left: 50px;
  `}

  ${minBreakpointQuery.xlarge`
    padding-right: 60px;
    padding-left: 60px;
  `}
`;

const ProductDetail = ({
  images,
  shopifyId,
  title,
  hasOnlyDefaultVariant,
  options,
  variants,
}) => (
  <StyledProductDetail>
    <StyledInner>
      <StyledProductGalleryWrapper>
        <StyledProductGallery
          images={images}
          title={title}
          variants={variants}
        />
      </StyledProductGalleryWrapper>
      <StyledProductSummaryFormWrapper>
        <StyledProductSummaryForm
          shopifyId={shopifyId}
          title={title}
          hasOnlyDefaultVariant={hasOnlyDefaultVariant}
          options={options}
          variants={variants}
        />
      </StyledProductSummaryFormWrapper>
    </StyledInner>
  </StyledProductDetail>
);

export default ProductDetail;
