import React from 'react';
import { Navigation } from 'swiper';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Container, Heading, Slider } from './ui';

const StyledProductsSlider = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledHeading = styled(Heading)`
  ${({ isRelatedProducts }) => {
    if (isRelatedProducts) {
      return css`
        ${fontSize(20)};
        text-transform: uppercase;
      `;
    }
  }}
`;

const StyledButtons = styled.div`
  display: inline-block;
  margin-left: 15px;
  white-space: nowrap;

  ${minBreakpointQuery.small`
    margin-left: 20px;
  `}

  ${minBreakpointQuery.medium`
    margin-left: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 30px;
  `}
`;

export const commonButtonStyles = () => {
  return css`
    padding: 2px;
    background: none;
    border: none;

    &:before {
      content: '➔';
      display: block;
      color: ${brandColours.primary};
      ${fontSize(21)};
      line-height: 0.8;
      transition: ${standardTransition('color')};

      ${minBreakpointQuery.small`
        ${fontSize(24)};
      `}

      ${minBreakpointQuery.medium`
        ${fontSize(27)};
      `}

      ${minBreakpointQuery.large`
        ${fontSize(30)};
      `}
    }

    span {
      ${visuallyHidden()};
    }

    &:hover {
      &:before {
        color: ${brandColours.tertiary};
      }
    }
  `;
};

const StyledButtonPrev = styled.button`
  ${commonButtonStyles()};

  &:before {
    transform: rotate(180deg);
  }
`;

const StyledButtonNext = styled.button`
  ${commonButtonStyles()};
  margin-left: 10px;

  ${minBreakpointQuery.small`
    margin-left: 15px;
  `}

  ${minBreakpointQuery.medium`
    margin-left: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 25px;
  `}
`;

const ProductsSlider = ({
  heading,
  sliderOptions,
  isRelatedProducts,
  children,
}) => (
  <StyledProductsSlider>
    <Container>
      <StyledHeader>
        <StyledHeading alt={true} isRelatedProducts={isRelatedProducts}>
          {heading}
        </StyledHeading>
        <StyledButtons>
          <StyledButtonPrev className="slider-button-prev">
            <span>Previous</span>
          </StyledButtonPrev>
          <StyledButtonNext className="slider-button-next">
            <span>Next</span>
          </StyledButtonNext>
        </StyledButtons>
      </StyledHeader>
      <Slider
        modules={[Navigation]}
        navigation={{
          prevEl: '.slider-button-prev',
          nextEl: '.slider-button-next',
        }}
        {...sliderOptions}
      >
        {children}
      </Slider>
    </Container>
  </StyledProductsSlider>
);

export default ProductsSlider;
