import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, standardColours, fontWeights } from '../styles';
import { Button } from './ui';
import ProductQuantity from './ProductQuantity';
import { StoreContext } from '../context/StoreContext';

const StyledProductForm = styled.form``;

const StyledOption = styled.label`
  display: block;
  margin-bottom: 20px;
`;

const StyledOptionName = styled.span`
  color: ${standardColours.black};
  font-weight: ${fontWeights.semibold};
`;

const StyledSelectWrapper = styled.span`
  position: relative;
  display: block;
  margin-top: 5px;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    display: block;
    height: 8px;
    width: 8px;
    border: 2px solid ${standardColours.darkGrey};
    border-top: 0;
    border-left: 0;
    border-radius: 1px;
    pointer-events: none;
    transform: translateY(-75%) rotate(45deg);

    ${minBreakpointQuery.tsmall`
      right: 24px;
      height: 12px;
      width: 12px;
    `}
  }
`;

const StyledSelect = styled.select`
  padding-right: 35px;
  padding-left: 10px;
  height: 50px;
  width: 100%;
  appearance: none;
  border: 1px solid ${standardColours.darkGrey};

  ${minBreakpointQuery.tsmall`
    padding-right: 55px;
    padding-left: 20px;
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
  width: 100%;

  &:disabled {
    opacity: 0.6;
  }
`;

const ProductForm = ({
  hasOnlyDefaultVariant,
  options,
  variants,
  selectedVariant,
  setSelectedVariant,
  ...props
}) => {
  const { addProductsToCheckout } = useContext(StoreContext);

  const initialSelectedVariantOptions = options.map(({ name, values }) => ({
    name: name,
    value: values[0],
  }));

  const [selectedVariantOptions, setSelectedVariantOptions] = useState(
    initialSelectedVariantOptions
  );
  const [quantity, setQuantity] = useState(1);
  const [addingToBasket, setAddingToBasket] = useState(false);

  useEffect(() => {
    const selectedVariant = variants.find(
      ({ selectedOptions }) =>
        JSON.stringify(selectedOptions) ===
        JSON.stringify(selectedVariantOptions)
    );

    setSelectedVariant(selectedVariant);
  }, [variants, selectedVariantOptions, setSelectedVariant]);

  const updateSelectedVariantOptions = (name, value) => {
    const updatedSelectedVariantOptions = selectedVariantOptions.map(
      selectedVariantOption =>
        selectedVariantOption.name === name
          ? {
              name: name,
              value: value,
            }
          : selectedVariantOption
    );

    setSelectedVariantOptions(updatedSelectedVariantOptions);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const lineItems = [
      {
        variantId: selectedVariant.storefrontId,
        quantity: quantity || 1,
      },
    ];

    setAddingToBasket(true);

    await addProductsToCheckout(lineItems);

    setAddingToBasket(false);

    setQuantity(1);
  };

  return (
    <StyledProductForm {...props} onSubmit={e => handleSubmit(e)}>
      {!hasOnlyDefaultVariant &&
        options.map(({ id, name, values }) => (
          <StyledOption key={id}>
            <StyledOptionName>{name}</StyledOptionName>
            <StyledSelectWrapper>
              <StyledSelect
                onChange={e =>
                  updateSelectedVariantOptions(name, e.currentTarget.value)
                }
              >
                {values.map((value, id) => (
                  <option key={id} value={value}>
                    {value}
                  </option>
                ))}
              </StyledSelect>
            </StyledSelectWrapper>
          </StyledOption>
        ))}
      <ProductQuantity
        quantity={quantity}
        maxQuantity={selectedVariant.inventoryQuantity}
        setQuantity={setQuantity}
      />
      <StyledButton disabled={addingToBasket} alt={true}>
        {addingToBasket ? 'Adding...' : 'Add to Basket'}
      </StyledButton>
    </StyledProductForm>
  );
};

export default ProductForm;
