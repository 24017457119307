import React from 'react';
import styled from 'styled-components';
import { breakpointSizes } from '../styles';
import ProductsSlider from './ProductsSlider';
import ProductCard from './ProductCard';

const StyledRelatedProducts = styled.section``;

const RelatedProducts = ({ heading, items }) => {
  const sliderOptions = {
    spaceBetween: 20,
    slidesPerView: 1,
    breakpoints: {
      [breakpointSizes.tiny]: {
        slidesPerView: 2,
      },
      [breakpointSizes.smedium]: {
        slidesPerView: 3,
      },
      [breakpointSizes.mlarge]: {
        slidesPerView: 4,
      },
    },
  };

  return (
    items.length > 0 && (
      <StyledRelatedProducts>
        <ProductsSlider
          heading={heading}
          sliderOptions={sliderOptions}
          isRelatedProducts={true}
        >
          {items.map(item => (
            <ProductCard
              key={item.id}
              title={item.title}
              handle={item.handle}
              featuredImage={item.featuredImage}
              priceRangeV2={item.priceRangeV2}
              hasOnlyDefaultVariant={item.hasOnlyDefaultVariant}
              totalInventory={item.totalInventory}
              variants={item.variants}
            />
          ))}
        </ProductsSlider>
      </StyledRelatedProducts>
    )
  );
};

export default RelatedProducts;
