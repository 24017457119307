import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Svg } from './ui';
import { VariantPrice } from './Prices';
import ProductForm from './ProductForm';
import OutOfStockForm from './OutOfStockForm';
import deliveryIcon from '../images/delivery-icon.inline.svg';
import { StoreContext } from '../context/StoreContext';

const StyledProductSummaryForm = styled.section``;

const StyledName = styled.h1`
  font-weight: ${fontWeights.regular};
  ${fontSize(26)};

  ${minBreakpointQuery.tiny`
    ${fontSize(28)};
  `}

  ${minBreakpointQuery.small`
    ${fontSize(26)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(28)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `}
`;

const StyledPrice = styled(VariantPrice)`
  margin-top: 12px;
  ${fontSize(18)};

  ${minBreakpointQuery.tiny`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 15px;
    ${fontSize(22)};
  `}
`;

const StyledForm = styled(ProductForm)`
  margin-top: 20px;
`;

const StyledDeliveryInfo = styled.p`
  margin-top: 15px;
  padding: 12px;
  color: ${brandColours.tertiary};
  font-weight: ${fontWeights.semibold};
  text-align: center;
  text-transform: uppercase;
  border: 2px solid ${brandColours.tertiary};

  ${minBreakpointQuery.tiny`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
  `}

  svg {
    position: relative;
    bottom: 2px;
    margin-right: 12px;
    width: 28px;
    fill: ${brandColours.tertiary};
    vertical-align: middle;
  }
`;

const ProductSummaryForm = ({
  shopifyId,
  title,
  hasOnlyDefaultVariant,
  options,
  variants,
  ...props
}) => {
  const { client } = useContext(StoreContext);

  const [selectedVariant, setSelectedVariant] = useState(variants[0]);
  const [selectedVariantAvailability, setSelectedVariantAvailability] =
    useState(undefined);

  useEffect(() => {
    client.product.fetch(shopifyId).then(product => {
      setSelectedVariantAvailability(
        product.variants.find(
          variant =>
            variant.id === selectedVariant.shopifyId ||
            variant.id === selectedVariant.storefrontId
        ).available
      );
    });
  }, [client, shopifyId, setSelectedVariantAvailability, selectedVariant]);

  return (
    <StyledProductSummaryForm {...props}>
      <StyledName>{title}</StyledName>
      <StyledPrice variant={selectedVariant} />
      {selectedVariantAvailability === true ? (
        <StyledForm
          hasOnlyDefaultVariant={hasOnlyDefaultVariant}
          options={options}
          variants={variants}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
        />
      ) : selectedVariantAvailability === false ? (
        <OutOfStockForm
          productId={shopifyId}
          variantId={selectedVariant.shopifyId}
        />
      ) : (
        ''
      )}
      <StyledDeliveryInfo>
        <Svg image={deliveryIcon} />
        Free delivery on all orders
      </StyledDeliveryInfo>
    </StyledProductSummaryForm>
  );
};

export default ProductSummaryForm;
