import React, { useContext, useState } from 'react';
import { navigate, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
  standardTransition,
} from '../styles';
import { Link, Button } from './ui';
import { ProductPrice } from './Prices';
import { StoreContext } from '../context/StoreContext';
import { buildUrl } from '../utils';

// moved up for hover
const StyledButtons = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  transform: translateY(100%);
  transition: ${standardTransition('transform')};
`;

const StyledProductCard = styled.article`
  cursor: pointer;

  &:hover {
    ${StyledButtons} {
      transform: translateY(0);
    }
  }
`;

const StyledImageWrapper = styled.div`
  position: relative;
  display: block;
  border: 1px solid ${standardColours.grey};
  border-radius: 30px; // refactor
  overflow: hidden;
  -webkit-backface-visibility: hidden;
`;

const StyledImage = styled(GatsbyImage)``;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  padding-left: 12px;
  width: 100%;
  ${fontSize(12)};
  letter-spacing: 1px;
  border-radius: 0;

  ${minBreakpointQuery.tsmall`
    ${fontSize(13)};
  `}

  ${minBreakpointQuery.mlarge`
    ${fontSize(14)};
  `}
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  ${minBreakpointQuery.small`
    margin-top: 14px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 18px;
  `}
`;

const StyledHeading = styled.h3`
  font-weight: ${fontWeights.regular};
  ${fontSize(16)};

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}
`;

const StyledPrice = styled(ProductPrice)`
  margin-left: 12px;
  text-align: right;

  ${minBreakpointQuery.small`
    margin-left: 15px;
    ${fontSize(18)};
  `}
`;

const ProductCard = ({
  title,
  handle,
  featuredImage,
  priceRangeV2,
  hasOnlyDefaultVariant,
  totalInventory,
  variants,
  ...props
}) => {
  const { addProductsToCheckout } = useContext(StoreContext);

  const [addingToBasket, setAddingToBasket] = useState(false);

  const addToBasket = async e => {
    e.preventDefault();

    const lineItems = [
      {
        variantId: variants[0].storefrontId,
        quantity: 1,
      },
    ];

    setAddingToBasket(true);

    await addProductsToCheckout(lineItems);

    setAddingToBasket(false);
  };

  return (
    <StyledProductCard onClick={() => navigate(buildUrl(handle))} {...props}>
      <StyledImageWrapper>
        <StyledImage
          image={featuredImage.gatsbyImageData}
          alt={featuredImage.altText || title}
        />
        <StyledButtons>
          <StyledButton to={handle}>View Product</StyledButton>
          {hasOnlyDefaultVariant && totalInventory > 0 && (
            <StyledButton
              disabled={addingToBasket}
              alt={true}
              onClick={e => addToBasket(e)}
            >
              {addingToBasket ? 'Adding...' : 'Add to Basket'}
            </StyledButton>
          )}
        </StyledButtons>
      </StyledImageWrapper>
      <StyledContent>
        <StyledHeading>
          <Link to={handle}>{title}</Link>
        </StyledHeading>
        <StyledPrice priceRange={priceRangeV2} variants={variants} />
      </StyledContent>
    </StyledProductCard>
  );
};

export default ProductCard;

export const ProductCardFragment = graphql`
  fragment ProductCardFragment on ShopifyProduct {
    id
    title
    handle
    featuredImage {
      gatsbyImageData(width: 400, height: 400)
      altText
    }
    ranges: tags
    size: metafield(key: "size", namespace: "my_fields") {
      value
    }
    colour: metafield(key: "colour", namespace: "my_fields") {
      value
    }
    ovenproof: metafield(key: "ovenproof", namespace: "my_fields") {
      value
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    hasOnlyDefaultVariant
    totalInventory
    variants {
      storefrontId
      price
      compareAtPrice
    }
  }
`;
